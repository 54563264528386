body {
  margin: 0;
}

html, body, * {
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
}

h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

p, a{
  font-size: 1.2rem;
}

a, a:visited, a:hover, a:active {
  color: inherit;
}